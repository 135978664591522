import React from 'react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { ISoloWeapon } from '../../../common/model/graphql-types';

interface IProps {
  employee: ISoloWeapon;
  showLabel?: boolean;
}

export const SoloWeaponIcon: React.FC<IProps> = ({ employee, showLabel }) => {
  const image = employee.smallImage
    ? getImage(employee.smallImage.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className={`avatar solo rar-${employee.rarity}`}>
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${employee.name}`}
          />
        )}
        <span className="floating-element afk">
          {employee.element === 'Water' && (
            <>
              <StaticImage
                src="../../../../images/solo/icons/ele_water.webp"
                alt="Water"
                className="faction-icon"
              />
            </>
          )}
          {employee.element === 'Wind' && (
            <>
              <StaticImage
                src="../../../../images/solo/icons/ele_wind.webp"
                alt="Wind"
                className="faction-icon"
              />
            </>
          )}
          {employee.element === 'Fire' && (
            <>
              <StaticImage
                src="../../../../images/solo/icons/ele_fire.webp"
                alt="Fire"
                className="faction-icon"
              />
            </>
          )}
          {employee.element === 'Light' && (
            <>
              <StaticImage
                src="../../../../images/solo/icons/ele_light.webp"
                alt="Light"
                className="faction-icon"
              />
            </>
          )}
          {employee.element === 'Dark' && (
            <>
              <StaticImage
                src="../../../../images/solo/icons/ele_darkness.webp"
                alt="Dark"
                className="faction-icon"
              />
            </>
          )}
        </span>
      </div>
      {showLabel && <span className="emp-name">{employee.name}</span>}
      {employee.investment && (
        <span className="tag mark">{employee.investment}</span>
      )}
    </>
  );
};
