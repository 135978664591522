import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { ISoloWeapon } from '../../../common/model/graphql-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import './solo-weapon-card.scss';
import { Accordion } from 'react-bootstrap';

interface IProps {
  weapon: ISoloWeapon;
}

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

export const SoloWeaponCard: React.FC<IProps> = ({ weapon }) => {
  const image = weapon.smallImage
    ? getImage(weapon.smallImage.localFile.childImageSharp)
    : null;
  return (
    <>
      <div className="solo-weapon-box box">
        <div className="solo-weapon-header">
          <div className={`solo-weapon-image rar-${weapon.rarity}`}>
            {image && (
              <GatsbyImage
                className="disable-transition"
                image={image}
                alt={`${weapon.name}`}
              />
            )}
          </div>
          <div className="solo-weapon-data">
            <h4>{weapon.name}</h4>
            <div className="solo-weapon-info">
              <p>
                Rarity:{' '}
                <strong className={`rarity-solo rar-${weapon.rarity}`}>
                  {weapon.rarity}
                </strong>
              </p>
              <p>
                Element:{' '}
                <strong className={`element ${weapon.element}`}>
                  {weapon.element}
                </strong>
              </p>
              <p>
                Type:{' '}
                <strong>
                  {weapon.type === 'Sung' ? 'Sung Jinwoo' : 'Hunter'}
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="solo-weapon-content">
          <div className={`skill-with-coloring`}>
            {renderRichText(weapon.passive, options)}
          </div>
          {weapon.passiveEffects && (
            <div className={`skill-with-coloring`}>
              {renderRichText(weapon.passiveEffects, options)}
            </div>
          )}
        </div>
        {weapon.dupes.dupe_1 != '' && (
          <Accordion className="default-look">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dupes information</Accordion.Header>
              <Accordion.Body>
                <div className={`skill-description`}>
                  <p className="stars">
                    &#10022; &#10023; &#10023; &#10023; &#10023;
                  </p>
                  <p
                    className="dupe-info"
                    dangerouslySetInnerHTML={{
                      __html: weapon.dupes.dupe_1
                    }}
                  />
                  <p className="stars">
                    &#10022; &#10022; &#10023; &#10023; &#10023;
                  </p>
                  <p
                    className="dupe-info"
                    dangerouslySetInnerHTML={{
                      __html: weapon.dupes.dupe_2
                    }}
                  />
                  <p className="stars">
                    &#10022; &#10022; &#10022; &#10023; &#10023;
                  </p>
                  <p
                    className="dupe-info"
                    dangerouslySetInnerHTML={{
                      __html: weapon.dupes.dupe_3
                    }}
                  />
                  <p className="stars">
                    &#10022; &#10022; &#10022; &#10022; &#10023;
                  </p>
                  <p
                    className="dupe-info"
                    dangerouslySetInnerHTML={{
                      __html: weapon.dupes.dupe_4
                    }}
                  />
                  <p className="stars">
                    &#10022; &#10022; &#10022; &#10022; &#10022;
                  </p>
                  <p
                    className="dupe-info"
                    dangerouslySetInnerHTML={{
                      __html: weapon.dupes.dupe_5
                    }}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
    </>
  );
};
