import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ISoloWeapon } from '../../../common/model/graphql-types';
import { SoloWeaponIcon } from './solo-weapon-icon';
import { SoloWeaponCard } from './solo-weapon-card';

interface IProps {
  id?: string;
  slug?: string;
  name?: string | number;
  showLabel?: boolean;
  mode: string;
  unitId?: string;
}

export const SoloWeapon: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  slug,
  name
}) => {
  const allSoloWeapons = useStaticQuery(graphql`
    query {
      allContentfulSoloWeapon {
        nodes {
          ...SoloWeaponFieldsFragment
        }
      }
    }
  `);
  if ((!id && !slug && !unitId && !name) || !mode) {
    return <div>Err</div>;
  }

  const character = allSoloWeapons.allContentfulSoloWeapon.nodes.find(
    (emp: ISoloWeapon) => (id ? emp.id === id : name && emp.name === name)
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <SoloWeaponIcon employee={character} showLabel={showLabel} />
      )}
      {mode === 'card' && <SoloWeaponCard weapon={character} />}
    </>
  );
};
